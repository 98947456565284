import { useEffect, useState } from "react";
import { motion, AnimatePresence } from 'framer-motion';
import { tryLocalStorage, timeSleep } from "utils";
import { useAppContext } from "contexts/AppContext";
import PromptContainer from "@/components/elements/PromptContainer/PromptContainer";
import Button from "@/components/elements/Button/Button";
import styles from './Plus18.module.scss';

const Plus18 = ({}) => {

    const { userStatus } = useAppContext();
    const [show, setShow] = useState(false);
    const [checked, setChecked] = useState(false);

    useEffect(()=>{
        async function tryAndSetModal(){
            const isPlus18 = tryLocalStorage.get('isPlus18');
            (!isPlus18 && userStatus.loggedIn === false) && (await timeSleep(2000), setShow(true));
        }
        tryAndSetModal();
    }, [userStatus.loggedIn])

    function handleSubmit(){
        setShow(false)
        tryLocalStorage.set('isPlus18', true)
    }

    return (
        <PromptContainer show={show} zIndex={4}>
            <div className={styles.plus18}>
                <div className={styles['plus-18-new-logo-container']}><img className={styles['plus-18-new-logo-image']} src="next/ag-new-logo-transparent.png"/></div>
                <div className={styles.question}>are you ready for pleasure?</div>
                <div className={styles.divider}></div>
                <div>you must be 18+ to come inside and explore</div>
                <div className={styles.consent}>
                    <InputRadio checked={checked} onChange={(value)=>setChecked(value)} />
                </div>
                <Button active={checked} onClick={handleSubmit}>Submit</Button>
            </div>
        </PromptContainer>
     );
}

const InputRadio = ({checked, onChange}) => {
    return (
      <>
          <input
              type="checkbox"
              value="18+"
              id="over18"
              onClick={()=>{
                  onChange(!checked)
              }}
              checked={checked}
          />
          <label htmlFor="over18" className={styles.consentLabel}>
              yes, I am 18 years of age or older
          </label>
      </>
    )
}

export default Plus18;
