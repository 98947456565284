import { countries } from "utils"


export const planA = [{
    name:"One Day",
    price: 2.99,
    period: "month",
    fee: "daily",
    detail: "billed $9.99 per month after 1st day",
    interval: "day",
},{
    name:"Monthly",
    price: 9.99,
    period: "month",
    fee: "monthly",
    detail: false,
    interval: "month",
},{
    name:"Annual",
    price: 69,
    period: "year",
    fee: "one time",
    detail: "$5.75 per month billed in one installment of $69",
    interval: "year",
}]

export const planB = [{
    name: "One Day",
    price: 4.99,
    period: "month",
    fee: "daily",
    detail: "billed $19.99 per month after 1st day",
    interval: "day"
},{
    name: "Monthly",
    price: 19.99,
    period: "month",
    fee: "monthly",
    detail: false,
    interval: "month"
},{
    name: "Annual",
    price: 69,
    period: "year",
    fee: "one time",
    detail: "$5.75 per month billed in one installment of $69",
    interval: "year"
}]

export const plans = [planA, planB];

export const signUpConfig = {
    defaultCountryIndex: 173
}

export const getSubscriptionObj = (obj) => {
    return {
        firstName: obj.firstName,
        lastName: obj.lastName,
        cardNumber: obj.cardNumber,
        subscriptionAmount: obj.subscriptionAmount,
        subscriptionInterval: obj.plan.interval,
        expirationDate: `20${obj.year}-${obj.month}`,
        cvv: obj.cvv,
        country: obj.country,
        zipCode: obj.zipCode,
        accessCode: obj.accessCode.valid ? obj.accessCode.code : null,
        abPriceDaily: obj.abPriceDaily,
        abPriceMonthly: obj.obPriceMonthly,
        abPriceAnnual: obj.abPriceAnnual,
    }
}

export const animateStepChange = (setSwitchAnimation) => {
    setSwitchAnimation(true)
    return setTimeout(() => {
        setSwitchAnimation(false)
    }, 200);
}

export const initialSignUpInfo = {
    email: "", password: "", plan: null, customPrice: "", country:countries[signUpConfig.defaultCountryIndex].name, accessCode: {code: false, type: null, text:"", valid: null}, shouldAb: false
}

export const REFERRAL = "referral"
export const MAIN = "main"
export const AFFILIATE = "affiliate"
export const PORNHUB = "pornhub"
export const FLASH_SALE = "flash_sale"

export const signupTypes = [REFERRAL, MAIN, AFFILIATE, PORNHUB, FLASH_SALE]

const main = {
    stepOne: {
        title: "You're almost there",
        subTitle: "We like edge play around here 😉",
        showCodeInput: false,
    },
    stepTwo: {
        title: "Choose your level of pleasure",
        plans
    },
    stepThree: {
        title: "A universe of pleasure is a click away",
        showCodeInput: true,
    }
}

const referral = {
    stepOne: {
        title: "You have great friends",
        subTitle: "Someone just gave you a free month of high quality, ethical porn and sexual wellness.",
        showCodeInput: false,
    },
    stepTwo: {
        title: "A whole new level of pleasure",
        plans: [{name: "Unlimited", price: 9.99, period: "month", type: REFERRAL}]
    },
    stepThree: {
        title: "A universe of pleasure is a click away",
        showCodeInput: false,
    }
}

const affiliate = {
    ...main,
    stepOne: {
        ...main.stepOne,
        showCodeInput: true
    }
}

const pornhub = {
    ...main,
    stepOne: {
        ...main.stepOne,
        showCodeInput: true
    }
}

const flash_sale = {
    ...main,
    stepOne: {
        ...main.stepOne,
        title: "Flash sale applied! 🏷️🔥",
        subTitle: "Create an account and access a special offer 🙌"
    },
    stepTwo: {
        ...main.stepTwo,
        plans: [
            ...plans.slice(0,2),
            [
                ...plans[0].slice(0,2),
                {
                    price: 69,
                    salePrice: 49,
                    detail: "$4 per month billed in one installment of $49",
                }
            ]
        ]
    }
}

export const signups = {
    [MAIN]: main,
    [REFERRAL]: referral,
    [AFFILIATE]: affiliate,
    [PORNHUB]: pornhub,
    [FLASH_SALE]: flash_sale
}
