export const REFERRAL_CTA_CLICKED = 'Send Referral CTA Clicked - Client';
export const FULFILLED = "fulfilled";
export const FAILURE = "failure";
export const NOT_LOGGED_IN = "not logged in";

export const AWS_SOURCE_PORTION = '.cloudfront.net';
export const XO_API_PATH = '/nextapi/videos/sign?url='

// Segment events
export const E_QUIZ_COMPLETED = 'Quiz Completed - Client';
export const E_REFERRAL_SENT = 'Referral sent';
export const E_CTA_CLICKED = 'CTA Clicked - Client';
export const E_ACCOUNT_CREATED = 'Account Created - Client';
export const E_JOURNEY_COMPLETED = 'Completed Journey';
export const E_VIEW_JOURNEY_HOMEPAGE = 'Viewed Journey Homepage'; 
export const E_SELECTED_JOURNEY = 'Selected Journey';
export const E_CHOOSE_PLAN = 'Choose Plan - Client';
export const E_TRIAL_CREATED = 'Trial Created - Client';
export const E_SUBSCRIPTION_CREATED = 'Subscription Created - Client';
export const E_SUBSCRIPTION_CANCELLED = 'Subscription Cancelled - Client';
export const E_FILTER_SELECTED = 'Filter Selected';
export const E_FILTER_CLEARED = 'Filter Cleared';
export const E_PORNHUB_LANDING_VISITED = 'PornHub Landing Page Visited'

export const WORDPRESS_POSTS_ENDPOINT = 'https://xoafterglow.com/blog/wp-json/wp/v2/posts';
