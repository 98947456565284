import { useAppContext } from 'contexts/AppContext';
import { useRouter } from 'next/router';
import { track } from 'utils';
import { REFERRAL_CTA_CLICKED } from 'utils/constants';
import Link from 'next/link';
import CardIcon from '@/components/elements/CardIcon/CardIcon';
import SocialIcon from '@/components/elements/SocialIcon/SocialIcon';
import styles from './Footer.module.scss';

const Footer = () => {

    const router = useRouter();
    const { userStatus } = useAppContext();
    const RAF_CTA = 'Refer a Friend'

    const d = new Date();
    const year = d.getFullYear();
    return (
        <footer className={styles.footer}>
            <div>
                <div className={styles.footer__social}>
                    <SocialIcon name="twitter" width={40} />
                    <SocialIcon name="instagram" width={40} />
                    <SocialIcon name="facebook" width={40} />
                    <SocialIcon name="tiktok" width={40} />
                </div>
                <div className={styles.footer__legal}>
                    <div>© {year} Afterglow. All Rights Reserved.</div>
                    <div className={styles.footer__legalTabs}>
                        <div>
                            <Link href={`/terms-and-conditions`}>Terms and Conditions</Link>
                        </div>
                        <div>
                            <Link href={`https://xoafterglow.com/report-an-issue/`}>Report an Issue</Link>
                        </div>
                        <div>
                            <Link href={`/18-usc-2257-record-keeping`}>18 U.S.C. 2257 Record-Keeping</Link>
                        </div>
                        <div>
                            <Link href={`/privacy-policy`}>Privacy Policy</Link>
                        </div>
                        <div>
                            <Link href={`/about`}>About</Link>
                        </div>
                        <div>
                            <Link href="/contact-us">Contact Us</Link>
                        </div>
                        {userStatus?.loggedIn &&
                            <div onClick={()=>track(REFERRAL_CTA_CLICKED, {cta: RAF_CTA, location: router.asPath })}>
                                <Link href="/refer-a-friend">
                                    <a>
                                    {RAF_CTA}
                                    </a>
                                </Link>
                            </div>
                        }
                    </div>
                    <div className={styles.footer__divider}></div>
                </div>
                <div className={styles.footer__payment}>
                    <div>
                        <div>Pay by</div>
                        <CardIcon name="visa" width={40} />
                        <CardIcon name="discover" width={40} />
                        <CardIcon name="mastercard" width={40} />
                    </div>
                </div>
            </div>
        </footer>
     );
}

export default Footer;
