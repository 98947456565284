import { useEffect, useState } from 'react';
import { useAppContext } from 'contexts/AppContext';
import { getUserStatus } from 'api';
import { useRouter } from 'next/router';
import { canAccessRoute, tryLocalStorage } from 'utils';
import Head from 'next/head';
import Nav from '@/components/modules/Nav/Nav';
import Footer from '@/components/modules/Footer/Footer';
import Plus18 from '../modules/Plus18/Plus18';
import FullScreenLoader from "@/components/modules/FullScreenLoader/FullScreenLoader";

const SITE_TITLE = "afterglow | ethical porn made by women, enjoyed by everyone"
const SITE_DESCRIPTION = "Afterglow combines high-quality ethical porn with sexual wellness exercises and education. Find original story-driven porn, guided masturbations and more!"

const MainLayout = ({children, nav, footer=true, authenticateClientSide, bodyScripts}) => {

    const router = useRouter();
    const { userStatus, setUserStatus } = useAppContext();
    const [authenticating, setAuthenticating] = useState(authenticateClientSide && !userStatus.isActive);
    const [showedOptIn, setShowedOptIn] = useState(null)

    useEffect(()=>{
        async function handleClientSideAuthentication(){
            if(authenticateClientSide && !canAccessRoute(router.pathname, userStatus)){
                setAuthenticating(true)
                const userStatusRes = await getUserStatus()
                userStatusRes.status === 200 && setUserStatus({
                    ...userStatusRes.data,
                    hasFetched: true,
                })
                if(canAccessRoute(router.pathname, userStatusRes.data)){
                    setAuthenticating(false)
                }else{
                    if(userStatusRes?.data?.loggedIn){
                        router.push(`/account`)
                    }else{
                        router.push(`/login?from=${router.asPath}`)
                    }
                }
            }else{
                setAuthenticating(false)
            }
        }
        handleClientSideAuthentication()
    }, [router, authenticateClientSide, setUserStatus, userStatus.isActive])

    useEffect(()=>{
        function shouldShowPopUp(){
            return !userStatus?.loggedIn && router.pathname !== '/login' && router.pathname !== '/signup' && router.pathname !== '/traffic-junky' && !showedOptIn
        }
        let timeout = setTimeout(() => {
          if(shouldShowPopUp()){
              history.pushState({}, '', '?freeMovie=lip-service')
              setShowedOptIn(true)
              tryLocalStorage.set('showedOptIn', true)
          }
        }, 30000);
        return ()=>clearTimeout(timeout)
      }, [router.pathname, userStatus, showedOptIn])

    return (
        <>
            <Head>
                <title>afterglow | ethical porn made by women, enjoyed by everyone</title>
                {
                    process.env.NODE_ENV === 'staging' &&
                    <meta name="robots" content="noindex" />

                }
                <meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=5" />
                <meta name="title" content={SITE_TITLE} key="meta_title" />
                <meta name="description" content={SITE_DESCRIPTION} key="meta_description" />

                {/* Facebook Meta  */}
                <meta property="og:url" content="https://xoafterglow.com" key="meta_url_facebook" />
                <meta property="og:title" content={SITE_TITLE} key="meta_title_facebook"  />
                <meta property="og:site_name" content={SITE_TITLE} />
                <meta property="og:description" content={SITE_DESCRIPTION} key="meta_description_facebook" />
                <meta property="og:image" content="/next/porn-4-women.webp" key="meta_image_facebook" />
                <meta property="og:image:secure_url" content="/next/porn-4-women.webp" key="meta_image_secure_facebook" />
                <meta property="og:image:alt" content={SITE_TITLE} key="meta_image_alt_facebook" />

                {/* Twitter Meta  */}
                <meta name="twitter:card" content="summary_large_image" />
                <meta name="twitter:site" content="@xoafterglow" />
                <meta name="twitter:url" content="https://xoafterglow.com" key="meta_url_twitter" />
                <meta name="twitter:creator" content="@xoafterglow" />
                <meta name="twitter:title" content={SITE_TITLE} key="meta_title_twitter" />
                <meta name="twitter:description" content={SITE_DESCRIPTION} key="meta_description_twitter" />
                <meta name="twitter:image" content="/next/porn-4-women.webp" key="meta_image_twitter" />
                <meta name="twitter:image:src" content="/next/porn-4-women.webp" key="meta_image_src_twitter" />

                <link rel="canonical" href={process.env.NEXT_PUBLIC_APP_URL} />
                <link rel="apple-touch-icon" href="/next/afterglow-icon.png"></link>
                <link rel="icon" type="image/x-icon" href="/next/afterglow-icon.png"></link>
                <script type="text/javascript" defer src="/next/js/intercom.js"></script>
                <script type="text/javascript" defer src="https://static.klaviyo.com/onsite/js/klaviyo.js?company_id=Tt8ELa"></script>
                {userStatus.loggedIn &&  <script defer src="https://e4bf6c6d6c16431ca4b73aad920e7360.js.ubembed.com" async /> }
            </Head>
            { nav ? nav : <Nav /> }
             {authenticating && <FullScreenLoader />}
            <Plus18 />
            {children}
            {bodyScripts}
            {footer && <Footer />}
        </>
     );

}

export default MainLayout;
